@font-face {
  font-family: "Neue400";
  src: url("./Assets/Fonts/Neue\ Haas\ Unica\ W1G\ Regular.otf")
    format("truetype");
}
@font-face {
  font-family: "NeueMed";
  src: url("./Assets/Fonts/Neue\ Haas\ Unica\ W1G\ Medium.otf")
    format("truetype");
}
@font-face {
  font-family: "NeueBold";
  src: url("./Assets/Fonts/Neue\ Haas\ Unica\ W16\ Bold.otf") format("truetype");
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
